import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Process = makeShortcode("Process");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const List = makeShortcode("List");
const Link = makeShortcode("Link");
const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const AudioPlayer = makeShortcode("AudioPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "️-asking-better-questions",
      "style": {
        "position": "relative"
      }
    }}>{`⁉️ Asking Better Questions`}<a parentName="h1" {...{
        "href": "#%EF%B8%8F-asking-better-questions",
        "aria-label": "️ asking better questions permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Let's start this week with a short list:`}</p>
    <Process mdxType="Process">
      <p>{`The most simple, seemingly silly questions are almost always the most profound.`}</p>
      <p>{`Good questions must come from a sincere desire to learn, rather than as a veiled means of
stating your own opinion.`}</p>
      <p>{`Experts rarely ask good questions. Be a beginner, always.`}</p>
      <p>{`Questions are an opportunity to be humbled. Asking good questions is indistinguishable from
`}<em parentName="p">{`practicing humility`}</em>{`.`}</p>
      <p>{`Own up to what you don't know. It will set others free and you may end up coming closer to
the shared truths which are a hallmark of honest conversation between peers.`}</p>
      <p>{`Close listening and clear attention are `}<strong parentName="p">{`preconditions`}</strong>{` for good questions.`}</p>
      <p>{`A real master is the pupil in the eye of the world. Don't be afraid of letting others teach
you; it does not degrade you, it uplifts you. The ego doesn't always acknowledge this simple truth.`}</p>
    </Process>
    <p>{`Asking better questions is about process and `}<strong parentName="p">{`practice`}</strong>{`. It's something you need to work on
consciously. It is a skill, not an innate personality trait and it can be developed in much the
same way that you can become better at coding, or cooking, or gardening.`}</p>
    <p>{`This goes to the heart of the thinking methodology we're advocating here. If you're able to
identify patterns of meaning by considering the complementary opposites implied by any idea you
are presented with, you will naturally ask better, simpler, and more direct questions.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: The sorts of shared truths which create `}<a parentName="p" {...{
            "href": "/learn/module-1/value"
          }}>{`value`}</a>{` are a hallmark of what kind of conversation between peers?`}</p>
        <p>{`Honest`}</p>
      </Card>
    </Flash>
    <p>{`The starting point is to ask yourself, "What does this really `}<em parentName="p">{`mean`}</em>{`?" It's not about "How can
I use this?" or "Why is everyone else excited?" It's about whether `}<strong parentName="p">{`you`}</strong>{` can understand the
basic principles. If you can't, it's either because it's not a well-formed idea (in which case,
move on!) or because you need to learn a new skill in order to understand its implications.
Which implies, again, that you need to be willing to learn if you are to practice the art of
asking better questions.`}</p>
    <p>{`How do you develop a willingness to learn? It begins with clarity and honesty about what you
don't know. As soon as you stop hiding behind all the ego's pretenses and claims to knowledge,
you will realise how little each of us actually knows. Such honesty automatically makes you
more incentivized to improve yourself, because no-one likes feeling inept (even though we all are).`}</p>
    <p>{`In so doing, you can begin to experience the benefits of beginner's mind yourself, not
conceptually, but in the actual course of your life. Beginner's mind is about starting now,
trying for yourself, being frustrated by your lack of expertise, and letting your heart show
your mind how to celebrate this fecund state of not-knowing - for therein lies both truth and
liberation.`}</p>
    <blockquote>
      <p parentName="blockquote">{`All of this echoes Rilke’s letters to a young poet in which he advises, “Have patience with everything unresolved and try to love the questions themselves.” I would add: “Try to love the questioners as well.”`}</p>
    </blockquote>
    <List mdxType="List">
      <Link to="https://www.yalom.com/gift-of-therapy-chapter-3" mdxType="Link">
        <p>{`Adventure with us`}</p>
      </Link>
      <List to="https://youtu.be/pnmO-8u4t4k?t=5265" mdxType="List">
        <p>{`The walls around paradise and remaining within the question`}</p>
      </List>
    </List>
    <h2 {...{
      "id": "68-life-lessons",
      "style": {
        "position": "relative"
      }
    }}>{`68 Life Lessons`}<a parentName="h2" {...{
        "href": "#68-life-lessons",
        "aria-label": "68 life lessons permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Unsolicited Advice`}</strong></p>
        <p><img parentName="p" {...{
            "src": "/images/mod2-img/penny.jpg",
            "alt": "Unsolicited Advice"
          }}></img></p>
        <p>{`We highly recommend you treat yourself to these unsolicited life lessons from Kevin Kelly. They will deepen your approach to the rest of this week's materials.`}</p>
        <Aligner center mdxType="Aligner">
          <Button to="https://web.archive.org/web/20220422203928/https://kk.org/thetechnium/68-bits-of-unsolicited-advice/" mdxType="Button">
    Read It
          </Button>
        </Aligner>
      </Box>
    </InfoBlock>
    <h2 {...{
      "id": "questioning-better-questions",
      "style": {
        "position": "relative"
      }
    }}>{`Questioning "Better" Questions`}<a parentName="h2" {...{
        "href": "#questioning-better-questions",
        "aria-label": "questioning better questions permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Link to="https://thebitcoinpodcast.com/" mdxType="Link">Corey Petty</Link> and Justin B question the claim made in point 3 above. The debate boils down to whether asking good questions in general is an ability best developed through disciplined exercise of rational skill, or the cultivation of "beginner's mind". In particular, techniques like <Link to="https://en.wikipedia.org/wiki/Socratic_method" mdxType="Link">the Socratic Method</Link> develop rational inquiry in order to fuel deeper, more intuitive understanding of a given topic. Corey writes:
    <blockquote>
      <p parentName="blockquote">{`Experience and reflection, learning from our mistakes, and developing understanding all
provide nuance. A mechanic doesn't develop his ability to diagnose a problem solely from
introspection. It is through the combination of knowledge and understanding that intuition
arises. I've spent most of my life honing skills that give me broad and deep understanding for
the `}<em parentName="p">{`specific purpose`}</em>{` of being able to ask good questions in any context.`}</p>
    </blockquote>
    <p>{`Or Justin B:`}</p>
    <blockquote>
      <p parentName="blockquote">{`I feel that knowledge of the specific, e.g. computer science, translates directly into
intuition around the general.`}</p>
    </blockquote>
    <p>{`The mechanic or scientist's skill does grow more nuanced through acquisition of knowledge and
skill by the exercise of discipline, and this does lead to more `}<em parentName="p">{`specific`}</em>{` questions about
utility. In this way, honing a skill leads to asking questions about that particular
discipline, which is a good thing. So, let us add an 8th point:`}</p>
    <p><strong parentName="p">{`8. Asking good questions requires a deep appreciation of context.`}</strong></p>
    <p>{`However, "beginner's mind" is not opposed to rational inquiry and the honing of skill: it is, in fact, the pinnacle of such discipline. Consider the `}<Link to="https://en.wikipedia.org/wiki/Socratic_method#Questioning_methods_in_Socratic_Circles" mdxType="Link">{`Socratic method`}</Link>{` more closely:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Socratic Circles are based upon the interaction of peers. The focus is to explore multiple perspectives on a given issue or topic. Socratic questions are open-ended, focusing on broad, general ideas rather than specific, factual information. The technique emphasizes a level of questioning and thinking where there is no single right answer.`}</p>
    </blockquote>
    <p>{`Rational inquiry demands a diagnosis; a solution; an end to a process which had its beginning in the mind. What can such a mind do in the face of "no single right answer"? It can develop `}<Link to="https://en.wikipedia.org/wiki/Negative_capability" mdxType="Link">{`negative capability`}</Link>{`, but even this is insufficient, because we're not after skillful intellectual understanding: we seek harmonious and clear `}<em parentName="p">{`ways of living`}</em>{`. And life is not only rational. `}</p>
    <p>{`Accepting honestly your own limitations and inability to understand everything that happens around you creates the space within for curiosity. From here, you can genuinely enjoy never knowing what's going to happen next, which leads to asking questions which come from the heart and go to the heart of the subject being questioned. It is, as `}<a parentName="p" {...{
        "href": "/learn/module-0/conversation/#old-gifts-anew"
      }}>{`Paul Myburgh`}</a>{` says, perceptual rather than conceptual: simple knowledge of things-as-they-are.`}</p>
    <h2 {...{
      "id": "listen",
      "style": {
        "position": "relative"
      }
    }}>{`Listen`}<a parentName="h2" {...{
        "href": "#listen",
        "aria-label": "listen permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <AudioPlayer src="https://kernel-audio.s3.amazonaws.com/M2E1+-+Ask.mp3" mdxType="AudioPlayer" />
    <h2 {...{
      "id": "stranger-things",
      "style": {
        "position": "relative"
      }
    }}>{`Stranger things`}<a parentName="h2" {...{
        "href": "#stranger-things",
        "aria-label": "stranger things permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><em parentName="p">{`When the Stranger says: "What is the meaning of this city?`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Do you huddle close together because you love each other?"`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`What will you answer? "We all dwell together`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`To make money from each other"? or "This is a community"?`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`And the Stranger will depart and return to the desert.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`O my soul, be prepared for the coming of the Stranger,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Be prepared for him who knows how to ask questions.`}</em>{`  `}</p>
        <p><a parentName="p" {...{
            "href": "https://www.poetrynook.com/poem/choruses-%C3%B4%C3%A7%C2%A3the-rock%C3%B4%C3%A7%C3%B8"
          }}>{`T. S. Eliot`}</a></p>
      </Box>
    </InfoBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      